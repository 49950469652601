<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import apiTool from '@/command/apiTool'
import { productTypeList, giveTypeList } from '@/utils/textFile'
import PopupIndex from './popupIndex.vue'
import { getAction } from '@/command/netTool'

export default {
  name: 'couponsDetail',
  data() {
    return {
      ...api.command.getState(),
      // 店铺搜索
      storeData: [],
      mainBodyTypeList: [],
      payProductTypeList: [],
      detail: {
        discountType: '1',
        crossStoreFlag: '0',
        couponAmount: null,
        receiveNum: null,
        giveNum: null,
        payAmount: null,
        effectiveDays: null,
        giveNum: null
      }
    }
  },
  watch: {
    'detail.expireType'(a, b) {
      if (a == 2) {
        this.detail.useEndTime = null
        this.detail.useStartTime = null
      }
    }
  },
  mounted() {
    getAction('/pay/queryPayProductTypeList').then(res => {
      if (res.code == 200) {
        this.payProductTypeList = res.data
      } else {
        this.$message.error(res.msg)
      }
    })
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/base/farmCouponRule/detail/${id}`
        })
        .then(result => {
          let productType = result.productType ? result.productType.split(',') : []
          let shopIds = result.shopIds ? result.shopIds.split(',') : []
          let payMode = result.payMode ? result.payMode.split(',') : []

          this.detail = {
            ...result,
            productType,
            shopIds,
            payMode
          }
          this.$forceUpdate()
        })

    this.storeSearch()
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/farmCouponActivity/list?activityName=${value ?? ''}`
      })
      this.mainBodyTypeList = searchResult.slice(0, 10).map(el => {
        return {
          name: el.activityName,
          value: el.id
        }
      })
    },
    hanldeIssueType() {
      return [
        {
          name: '发放时间',
          type: 'row',
          cols: 8,
          gutter: 10,
          display: this.detail.giveType == '1' || this.detail.giveType == '3',
          rules: [
            {
              required: true
            }
          ],
          children: [
            {
              label: '发放开始日期',
              type: 'datePicker',
              cols: 12,
              key: 'giveStartTime',
              props: {
                mode: 'date',
                format: 'YYYY-MM-DD',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放开始日期'
              },
              rules: [
                {
                  required: true,
                  type: 'date'
                }
              ]
            },
            {
              label: '发放开始时间',
              type: 'timePicker',
              cols: 12,
              key: 'giveStartTime',
              props: {
                format: 'HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                allowClear: false,
                placeholder: '请选择发放开始时间'
              },
              rules: [
                {
                  required: true,
                  type: 'date'
                }
              ]
            }
          ]
        },
        {
          name: '发放结束',
          type: 'row',
          cols: 8,
          gutter: 10,
          display: this.detail.giveType == '1' || this.detail.giveType == '3',
          rules: [
            {
              required: true
            }
          ],
          children: [
            {
              label: '发放结束日期',
              type: 'datePicker',
              cols: 12,
              key: 'giveEndTime',
              props: {
                mode: 'date',
                format: 'YYYY-MM-DD',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                placeholder: '请选择发放结束日期'
              },
              rules: [
                {
                  required: true,
                  type: 'date'
                }
              ]
            },
            {
              label: '发放结束时间',
              type: 'timePicker',
              cols: 12,
              key: 'giveEndTime',
              props: {
                format: 'HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm:ss',
                allowClear: false,
                placeholder: '请选择发放结束时间'
              },
              rules: [
                {
                  required: true,
                  type: 'date'
                }
              ]
            }
          ]
        },
        {
          name: '发放时间',
          type: 'row',
          cols: 16,
          gutter: 10,
          display: this.detail.giveType == '2',
          rules: [
            {
              required: true
            }
          ],
          children: [
            {
              type: 'select',
              cols: 12,
              key: 'giveTimeType',
              defaultValue: '1',
              typeData: [
                {
                  name: '立即',
                  value: '1'
                },
                {
                  name: '指定时间',
                  value: '2'
                }
              ]
            },
            ...this.handleGiveType()
          ]
        },
        {
          display: true,
          name: '券发放量',
          type: 'inputNumber',
          cols: 12,
          key: 'giveNum',
          rules: [
            {
              required: true,
              type: 'number'
            }
          ],
          props: {
            min: 0
          }
        },
        {
          display: this.detail.giveType != '4',
          name: '发放客户类型',
          type: 'row',
          cols: 4,
          rules: [
            {
              required: true,
              message: '发放客户类型'
            }
          ],
          children: [
            {
              type: 'radioButton',
              key: 'userType',
              cols: 24,
              typeData: [
                {
                  name: '所有用户',
                  value: '0'
                },
                {
                  name: '指定用户',
                  value: '1'
                }
              ],
              rules: [
                {
                  required: true,
                  message: '请选择发放客户类型'
                }
              ]
            }
          ]
        }
      ].filter(e => e.display)
    },
    handleGiveType() {
      return [
        {
          label: '发放开始日期',
          type: 'datePicker',
          cols: 6,
          key: 'giveStartTime',
          display: this.detail.giveTimeType == 2,
          props: {
            mode: 'date',
            format: 'YYYY-MM-DD',
            valueFormat: 'YYYY-MM-DD HH:mm:ss',
            placeholder: '请选择发放开始日期'
          },
          rules: [
            {
              required: true,
              type: 'date'
            }
          ]
        },
        {
          label: '发放开始时间',
          type: 'timePicker',
          cols: 6,
          key: 'giveStartTime',
          display: this.detail.giveTimeType == 2,
          props: {
            format: 'HH:mm',
            valueFormat: 'YYYY-MM-DD HH:mm:ss',
            placeholder: '请选择发放开始时间'
          },
          rules: [
            {
              required: true,
              type: 'date'
            }
          ]
        }
      ].filter(e => e.display)
    },
    handleDiscountType() {
      return [
        {
          name: '立减金额',
          type: 'inputNumber',
          cols: 8,
          key: 'couponAmount',
          display: this.detail.discountType == '1',
          props: {
            min: 0,
            addonAfter: '元'
          },
          rules: [
            {
              required: true,
              type: 'number'
            }
          ]
        },
        {
          name: '满减金额',
          type: 'inputNumber',
          cols: 4,
          key: 'payAmount',
          display: this.detail.discountType == '2',
          props: { min: 0, addonAfter: '元' },
          rules: [
            {
              required: true,
              type: 'number'
            }
          ]
        },
        {
          name: '立减金额',
          type: 'inputNumber',
          cols: 4,
          key: 'couponAmount',
          display: this.detail.discountType == '2',
          props: { min: 0, addonAfter: '元' },
          rules: [
            {
              required: true,
              type: 'number'
            }
          ],
          props: {}
        },
        {
          name: '折扣数量',
          type: 'inputNumber',
          cols: 8,
          key: 'couponAmount',
          display: this.detail.discountType == '3',
          props: { min: 0, addonAfter: '折' },
          rules: [
            {
              required: true,
              type: 'number'
            }
          ],
          props: {}
        }
      ].filter(e => e.display)
    },
    handleUserType() {
      if (this.detail.userType == 1 && this.detail.giveType != '4') {
        return [
          {
            name: '金额范围',
            type: 'row',
            cols: 12,
            gutter: 15,
            children: [
              {
                label: '最低购买金额',
                type: 'input',
                cols: 12,
                key: 'minBuyMoney'
              },
              {
                label: '最高购买金额',
                type: 'input',
                cols: 12,
                key: 'maxBuyMoney'
              }
            ]
          },
          {
            name: '购买次数范围',
            type: 'row',
            cols: 12,
            gutter: 15,
            children: [
              {
                label: '最低购买次数',
                type: 'input',
                cols: 12,
                key: 'minBuyNum'
              },
              {
                label: '最高购买次数',
                type: 'input',
                cols: 12,
                key: 'maxBuyNum'
              }
            ]
          }
        ]
      } else {
        return []
      }
    },
    handleSelectPu() {
      if (this.detail.shopType == 1) {
        return [
          {
            name: '选择店铺',
            type: 'dropDownMultiInput',
            cols: 8,
            key: 'mainBodyName',
            typeData: this.storeData,
            onInputSearch: this.storeSearch,
            valueKey: 'shopIds',
            inputProps: {
              addonBefore: <a-icon type="search" />
            }
          }
        ]
      } else {
        return []
      }
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '券名称(12字符内)',
            type: 'input',
            cols: 12,
            key: 'couponName',
            props: {
              maxLength: 12
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '优惠方式',
            type: 'select',
            cols: 4,
            key: 'discountType',
            typeData: [
              {
                name: '立减',
                value: '1'
              },
              {
                name: '满减',
                value: '2'
              },
              {
                name: '折扣',
                value: '3'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          ...this.handleDiscountType(),
          {
            name: '优惠券有效期',
            type: 'select',
            cols: 4,
            gutter: 10,
            key: 'expireType',
            defaultValue: '1',
            typeData: [
              {
                name: '固定时间',
                value: '1'
              },
              {
                name: '永久',
                value: '2'
              },
              {
                name: '领取后时间',
                value: '3'
              }
            ],
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择优惠券有效期类型'
              }
            ]
          },
          ...this.isTime(),
          {
            name: '活动集合（同个集合控制活动领取数量）',
            type: 'dropDownInput',
            cols: 6,
            key: 'activityName',
            onInputSearch: this.onMainBodySearch,
            valueKey: 'activityId',
            inputProps: {
              addonBefore: <a-icon type="search" />
            },
            typeData: this.mainBodyTypeList
          },
          {
            type: 'button',
            cols: 2,
            label: '管理分类',
            props: {
              style: {
                marginTop: '43px'
              },
              type: 'primary',
              ghost: true,
              on: {
                click: () => {
                  this.showManageClassify()
                }
              }
            }
          },
          {
            name: '每人领取限额',
            type: 'inputNumber',
            cols: 4,
            key: 'receiveNum',
            props: {
              addonAfter: '张',
              min: 0
            },
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '优惠券出资人',
            type: 'select',
            cols: 12,
            key: 'couponSource',
            typeData: [
              {
                name: '平台',
                value: '1'
              },
              {
                name: '商家',
                value: '2'
              },
              {
                name: '农行',
                value: '3'
              }
            ]
          },
          {
            name: '与余额券互斥',
            type: 'radioButton',
            cols: 4,
            key: 'balanceMutex',
            typeData: [
              {
                name: '不互斥',
                value: '0'
              },
              {
                name: '互斥',
                value: '1'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '类型',
            type: 'radioButton',
            cols: 4,
            key: 'couponType',
            typeData: [
              {
                name: '优惠券',
                value: '0'
              },
              {
                name: '数币券',
                value: '1'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '使用规则',
            type: 'switchOpen',
            cols: 4,
            key: 'useRuleFlag',
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    showManageClassify() {
      apiTool.showModal({
        width: '800px',
        title: '活动集合',
        footer: false,
        view: PopupIndex
      })
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        showCard: false,
        data: [
          {
            type: 'rich',
            key: 'useRule',
            cols: 24
          }
        ]
      }
    },
    isTime() {
      return [
        {
          display: this.detail.expireType == 1 || this.detail.expireType == 2,
          name: '开始结束时间',
          type: 'rangePicker',
          cols: 8,
          keys: ['useStartTime', 'useEndTime'],
          disabled: this.detail.expireType == 2,
          rules: [
            {
              required: true,
              message: '请选择开始结束时间'
            }
          ]
        },
        {
          display: this.detail.expireType == 3,
          name: '天数',
          type: 'inputNumber',
          cols: 8,
          key: 'effectiveDays',
          rules: [
            {
              required: true,
              type: 'number'
            }
          ]
        }
      ].filter(e => e.display)
    },
    getForm2() {
      return {
        title: '使用对象',
        type: 'cardForm',
        gutter: 10,
        data: [
          {
            name: '券针对产品类型',
            type: 'checkBoxButton',
            key: 'productType',
            cols: 24,
            typeData: productTypeList(),
            rules: [
              {
                required: true,
                type: 'array'
              }
            ]
          },
          {
            name: '商城优惠券',
            cols: 12,
            rules: [
              {
                required: true
              }
            ],
            type: 'radioButton',
            key: 'crossStoreFlag',
            typeData: [
              {
                name: '可跨店使用',
                value: '1',
                disabled: true
              },
              {
                name: '单店使用',
                value: '0'
              }
            ]
          },
          {
            name: '券针对商家',
            cols: 4,
            rules: [
              {
                required: true
              }
            ],
            type: 'radioButton',
            key: 'shopType',
            typeData: [
              {
                name: '所有商家',
                value: '0',
                styles: {
                  width: '120px'
                }
              },
              {
                name: '指定商家',
                value: '1',
                styles: {
                  width: '110px'
                }
              }
            ]
          },
          ...this.handleSelectPu()
        ]
      }
    },
    getForm5() {
      return {
        title: '支付方式',
        type: 'cardForm',
        data: (this.payProductTypeList || []).map(e => {
          return {
            name: e.payProductTypeLable,
            type: 'checkButton',
            key: 'payMode',
            cols: 12,
            typeData: e.payModeList.map(m => {
              return {
                name: m.modeName,
                value: m.modeCode
              }
            }),
            rules: [
              {
                required: true,
                type: 'array'
              }
            ]
          }
        })
      }
    },
    getForm3() {
      return {
        title: '发放设置',
        type: 'cardForm',
        data: [
          {
            name: '发放方式',
            type: 'select',
            cols: 8,
            key: 'giveType',
            typeData: giveTypeList,
            rules: [
              {
                required: true
              }
            ]
          },
          ...this.hanldeIssueType(),
          ...this.handleUserType()
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            if (data.expireType == 1) {
              if (data.useEndTime && data.useStartTime) {
                data.useEndTime = `${moment(data.useEndTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')}`
                data.useStartTime = `${moment(data.useStartTime).format('YYYY-MM-DD HH:mm:ss')}`
              } else {
                this.$message.warning('请选择开始结束时间！')
                return
              }
            }

            if (data.giveStartTime && data.giveEndTime) {
              if (!moment(data.giveStartTime).isBefore(data.giveEndTime)) {
                this.$message.warning('发放结束时间不能小于发放时间!')
                return false
              }
            }

            let shopIds
            if (data.shopIds) {
              shopIds = data.shopIds.length > 0 ? data.shopIds.toString() : ''
            }

            let productType
            if (data.productType) {
              productType = data.productType.length > 0 ? data.productType.toString() : ''
            }

            let params = {
              ...data,
              productType: productType,
              shopIds: shopIds,
              payMode: data.payMode.toString()
            }

            api.command[data.id ? 'editPost' : 'create']
              .call(this, {
                url: `/base/farmCouponRule/${data.id ? 'edit' : 'save'}`,
                params,
                isPost: false
              })
              .then(result => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/coupons')
        }
      ]
      const right = [
        {
          name: this.detail.publishStatus == 0 ? '上架' : '下架',
          onClick: () => {
            api.command.edit
              .call(this, {
                url: `/base/farmCouponRule/${this.detail.publishStatus == 0 ? 'up' : 'down'}?ids=${this.detail.id}`,
                isPost: false
              })
              .then(() => {
                this.$router.push('/marketingCenter/coupons')
              })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPut.call(this, {
              url: `/base/farmCouponRule/del?ids=${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/coupons')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[
          this.getForm1(),
          this.detail.useRuleFlag == '1' && this.getForm4(),
          this.getForm2(),
          this.getForm5(),
          this.getForm3()
        ]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
